/* eslint-disable react-perf/jsx-no-new-array-as-prop, no-magic-numbers */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Link } from 'panamera-react-ui';
import { compose } from 'redux';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';

import withRouter from 'HOCs/withRouter';
import withTrack from 'HOCs/withTrack/withTrack';
import { buildURL } from 'Helpers/url';
import { DYNAMIC_CONTENT } from 'Constants/tracking';
import { CATEGORIES_IDS, MAIN_CATEGORY } from 'Constants/categories';

import css from './DynamicContent.APP_TARGET.scss';
import { isMobile } from 'Helpers/devices';

const categories = [MAIN_CATEGORY.REAL_ESTATE, MAIN_CATEGORY.JOBS, MAIN_CATEGORY.MOBILES, MAIN_CATEGORY.BIKES];

const DynamicContent = ({ category, content, filter, selectedLocation, track }) => {
    const [open, setOpen] = React.useState(false);
    const { footer_text, footer_title, intro_text, table_data, table_headers, table_title } = content || {};

    function handleClick() {
        setOpen(!open);
        handleTrack('read_more')();
    }

    function getPayloadForUrl(row) {
        if (categories.indexOf(category?.id) > -1) {
            return {
                category: {
                    id: row.brand_id,
                    name: row.brand
                },
                location: selectedLocation
            };
        }
        else if (CATEGORIES_IDS.CARS.includes(category.id)) {
            return {
                category,
                location: selectedLocation,
                params: {
                    filter: {
                        ...filter,
                        ...(filter.make
                            ? { model: row?.brand_id || undefined }
                            : { make: row?.brand_id || undefined }
                        )
                    }
                }
            };
        }

        return {};
    }

    function getPageType() {
        if (['COUNTRY', 'CITY'].indexOf(selectedLocation?.type) > -1) {
            if (CATEGORIES_IDS.CARS.includes(category.id)) {
                return filter?.make ? 'dc_model_page' : 'dc_brand_page';
            }

            return selectedLocation?.type === 'COUNTRY' ? 'dc_india_page' : 'dc_location_page';
        }

        return {};
    }

    function handleTrack(type) {
        return () => {
            track(type === 'link' ? DYNAMIC_CONTENT.CLICKED_DC : DYNAMIC_CONTENT.CLICKED_READ_MORE, {
                category_id: category?.id,
                dc_page_type: getPageType()
            });
        };
    }

    // this function is added to resolve the ' no-nested-ternary ' issue and will be removed when removing the session feature flag
    function renderEmptyIntroTextContainer() {
        return <div className={ css.emptyIntroTextContainer } />;
    }

    return (
        <div className={ css.dynamicContentContainer }>
            {
                intro_text
                    ? <div className={ css.introTextContainer }>
                        <ReactMarkdown className={ classnames(css.introText, { [css.clipText]: !open, [css.noClipText]: isMobile && !open }) } rehypePlugins={ [rehypeRaw] }>{ intro_text }</ReactMarkdown>
                        <strong className={ classnames(css.expander, { [css.hide]: open }) } onClick={ handleClick }>
                            <Translation id="read_more" />
                        </strong>
                    </div>
                    : renderEmptyIntroTextContainer()
            }

            {
                table_title && open
                    ? <ReactMarkdown className={ css.introTitle } rehypePlugins={ [rehypeRaw] }>{ table_title }</ReactMarkdown>
                    : undefined
            }

            {table_headers?.length > 0 ? (
                <table className={ classnames({ [css.hide]: !open }) }>
                    <thead>
                        <tr>
                            {table_headers.map((col, idx) => (
                                <th key={ idx }>
                                    <ReactMarkdown rehypePlugins={ [rehypeRaw] }>{ col.display_name }</ReactMarkdown>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {table_data.map((row, idx) => (
                            <tr key={ idx }>
                                { table_headers.map((col, idx) => {
                                    const url = buildURL(getPayloadForUrl(row));

                                    return (
                                        <td key={ idx }>
                                            { col?.type === 'hyperLink' ? <Link data-aut-id={ 'link' } onClick={ handleTrack('link') } to={ url }>{ row[col.key] }</Link> : row[col.key] }
                                        </td>
                                    );
                                }) }
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : undefined}

            {
                footer_title
                    ? <ReactMarkdown className={ classnames(css.introTitle, css.footerTitle, { [css.hide]: !open }) } rehypePlugins={ [rehypeRaw] }>{ footer_title }</ReactMarkdown>
                    : undefined
            }
            {
                footer_text
                    ? <ReactMarkdown className={ classnames(css.introText, css.footerText, { [css.hide]: !open }) } rehypePlugins={ [rehypeRaw] }>{ footer_text }</ReactMarkdown>
                    : undefined
            }

            {
                intro_text ? (
                    <strong className={ classnames(css.expander, { [css.hide]: !open }) } onClick={ handleClick }>
                        <Translation id="collapse" />
                    </strong>
                ) : undefined
            }
        </div>
    );
};

DynamicContent.propTypes = {
    category: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    selectedLocation: PropTypes.object.isRequired,
    track: PropTypes.func.isRequired };

export default compose(
    withRouter,
    withTrack,
    injectIntl,
    withRouter)(DynamicContent);
