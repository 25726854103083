/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* global window */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Auth from '../../Auth';
import { FormattedMessage as Translation } from 'react-intl';
import * as helmetHelper from 'Helpers/helmet';
import * as listingHelper from 'Helpers/listing';
import { buildURL, locationToString } from 'Helpers/url';
import { noop } from 'Helpers/function';
import visualizationTypeActionCreator from 'Actions/visualizationType';
import toggleSorting from 'Actions/toggleSorting';
import setLoginModalState from 'Actions/setLoginModalState';
import { changeLocation, setFilters, setBrowsingModeSync, setOriginSync, setFromBundleResultsetType } from 'Actions/track';
import { Helmet } from 'react-helmet-async';
import AdvertisingComponent from 'Components/AdvertisingComponent/AdvertisingComponent';
import { css as uicss } from 'panamera-react-ui';
import css from './Listing.APP_TARGET.scss';
import { getCategoryTree } from '../../reducers/componentHelpers';
import classNames from 'classnames';
import withSEOTags from 'HOCs/withTags/withTags';
import withLocation from 'HOCs/withLocation/withLocation';
import withItems from 'HOCs/withItems/withItems';
import withTrack from 'HOCs/withTrack/withTrack';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { getPopularSearches as fetchPopularSearches } from 'Actions/seoPopularSearches';
import Page from 'Components/Page/Page';
import { listingBreadcrumbSelector } from 'Selectors/breadcrumb';
import { isAtCityLevelAndCategoryTreeSelected } from 'Helpers/seoFooter';
import SearchHeader from '../../components/HeaderWrapper/Headers/SearchHeader/SearchHeader.APP_TARGET';
import {
    CARS_CATEGORY_PAGE_SIZE,
    ITEMS_SEARCH_SOURCE,
    ITEM_SOURCE,
    SIMILAR_ADS_PAGE_SIZE,
    DECIMAL_RADIX
} from 'Constants/items';
import {
    LISTING,
    LISTING_TAP_SELECT_SORT,
    LISTING_TAP_SELECT_FILTERS,
    LISTING_TAP_APPLY_SORT,
    SELECT_FROM,
    FILTER,
    VIEW_LISTINGS_RESULTS_SUMMARY,
    CAR_COMPARE_FLOW_STEP
} from 'Constants/tracking';
import Notification from 'Components/Notification/Notification.jsx';
import { itemsPageIndexSelector, itemsSectionsSelector, suggestedSectionsSelector } from 'Selectors/items';
import Nav from 'Components/Listing/components/Nav/Nav.APP_TARGET';
import SubHeader from 'Components/SubHeader/AsyncSubHeader.APP_TARGET';
import Head from './components/Head/Head';
import { isEmpty } from 'Helpers/objects';
import { fetchFiltersByCategoryId as fetchFiltersByCategoryIdActionCreator, clearFilterRecencyOrder } from 'Actions/filters';
import { DEFAULT_CATEGORY } from 'Constants/categories';
import { decodeFilters, decodeRelevanceFilters } from 'Helpers/filters';
import { FACET_LIMIT, LISTING_BODY_TYPE } from 'Constants/listing';
import { categoryIdSelector } from 'Selectors/categories';
import CookieManager from 'Helpers/cookies';
import { SLIDER_TYPE } from 'Constants/listingSlider';
import { listingSliderTypeSelector } from 'Selectors/listingSlider';
import { getListingSliderData as getListingSliderDataActionCreator } from 'Actions/listingSlider';
import { REQUEST_ORIGINS, REQUEST_ORIGIN, LayoutHelper } from 'olx-autos-landing-page';
import { VISUALIZATION_KEY, visualizationFilters } from 'Constants/filters';
import { setItem as setSessionStorageItem } from 'Helpers/sessionStorage';
import { WHITE_TEXT } from 'Constants/colors';
import ListingHeader from './ListingOld/ListingHeader/ListingHeader';
import ListingBody from './ListingOld/ListingBody/ListingBody';
import loadable from '@loadable/component';
import { viewTypeTanakConfig } from 'Selectors/viewTypes';
import { LayoutSelector } from 'olx-autos-landing-page';
import { getItemCounts } from 'Helpers/item';
import { getTags } from 'Selectors/filtersTanak';
import { selectedLocationSelector } from 'Selectors/location';
import { getCityLocationId } from 'Helpers/locations';
import { PLATFORM, IS_DESKTOP, IS_MOBILE } from 'Constants/device.APP_TARGET';
import { getListingSliderType } from 'Helpers/listingSlider';
import { getShowBannerCarousel, getCarouselCategoryData } from 'Selectors/carousel';
import { configSelector } from 'Selectors/config';
import { BANNER_CAROUSEL_WIDGETS } from '../Listing/ListingOld/ListingPageContent/Constant';
import { addOfflineEventListeners, offlineHandler, removeOfflineEventListeners } from 'Helpers/offline';
import { getPopularLocationsByLimit } from 'Actions/locations';
import { LOCATION_TYPE, LOCATION_LIMIT } from 'Constants/footerInterLinkData';
import { scrollToTop } from 'Helpers/page';
import PaginationWrapper from '../MyAds/components/Pagination/PaginationWrapper';
import DynamicContent from 'Components/Listing/components/DynamicContent/DynamicContent';
import { getDynamicContentType } from 'Helpers/dynamicContent';
import withDynamicContent from 'HOCs/withDynamicContent/withDynamicContent';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';
// import withSkinAds from 'HOCs/withSkinAds/withSkinAds';

const { grid } = uicss;

const OLXAutosHeaderWrapper = loadable(() => import(/* webpackChunkName: "olxautos-header-wrapper" */'./../../olxautos/components/OLXAutosHeaderWrapper/OLXAutosHeaderWrapper.APP_TARGET'));
const ListingPageContentOld = loadable(() => import(/* webpackChunkName: "listingOld" */'./ListingOld/ListingPageContent/ListingPageContent.APP_TARGET'));

const Footer = loadable(() => import(/* webpackChunkName: "footer" */'Components/Footer/Footer'));

const TIME_FACTOR = 1000;

export class ListingPage extends React.Component {
    static propTypes = {
        marketConfig: PropTypes.shape({
            get: PropTypes.func.isRequired
        }).isRequired,
        items: PropTypes.arrayOf(PropTypes.object),
        params: PropTypes.object,
        text: PropTypes.string,
        category: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        categoryTreeSelected: PropTypes.object,
        router: PropTypes.shape({
            push: PropTypes.func.isRequired,
            replace: PropTypes.func.isRequired
        }).isRequired,
        location: PropTypes.object,
        toggleVisualization: PropTypes.func,
        isFiltersOpen: PropTypes.bool,
        isSortingOpen: PropTypes.bool,
        setFiltersTrack: PropTypes.func,
        setOriginTrack: PropTypes.func,
        setBrowsingMode: PropTypes.func,
        trackFilters: PropTypes.object,
        itemsMetadata: PropTypes.object,
        trackOrigin: PropTypes.string,
        setLoginModalState: PropTypes.func,
        itemsParams: PropTypes.object,
        onLoadNextPage: PropTypes.func,
        isFetchingItems: PropTypes.bool,
        isFetchingLocation: PropTypes.bool,
        locationCountry: PropTypes.string,
        parentItems: PropTypes.shape({
            locationFrom: PropTypes.string,
            locationTo: PropTypes.string
        }),
        breadcrumbLinks: PropTypes.array.isRequired,
        clearFilterRecencyOrder: PropTypes.func.isRequired,
        sectionsItems: PropTypes.arrayOf(
            PropTypes.shape({
                offset: PropTypes.number,
                location: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    type: PropTypes.string
                })
            })
        ),
        selectedLocation: PropTypes.object,
        suggestedItemsSections: PropTypes.arrayOf(
            PropTypes.shape({
                offset: PropTypes.number,
                location: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    type: PropTypes.string
                })
            })
        ),
        footer: PropTypes.array,
        itemsPageIndex: PropTypes.string,
        track: PropTypes.func,
        trackViewListingsImpressions: PropTypes.func,
        impressions: PropTypes.object,
        trackerOrigins: PropTypes.object,
        filtersStateConfig: PropTypes.object,
        getListingSliderData: PropTypes.func,
        setFromBundleResultsetType: PropTypes.func,
        listingSliderType: PropTypes.oneOf([SLIDER_TYPE.INSPECTION, SLIDER_TYPE.OLX_AUTOS, null]),
        trafficOrigin: PropTypes.string,
        browseMode: PropTypes.string,
        appliedFilters: PropTypes.array,
        craouselCategoryData: PropTypes.object,
        similarAdsData: PropTypes.array,
        showBannerAndPopularSearch: PropTypes.bool,
        seoCanonical: PropTypes.string,
        generalConfig: PropTypes.object.isRequired,
        dynamicContent: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        currentPageItems: PropTypes.array,
        sessionFeatures: PropTypes.arrayOf(PropTypes.string)
    };

    static defaultProps = {
        items: [],
        toggleVisualization: noop,
        text: '',
        category: '',
        isFiltersOpen: false,
        isSortingOpen: false,
        similarAdsData: [],
        changeLocationTrack: noop,
        setFiltersTrack: noop,
        setOriginTrack: noop,
        setBrowsingMode: noop,
        trackFilters: {},
        trackFiltersMetadata: {},
        itemsMetadata: {},
        trackOrigin: '',
        itemsParams: {},
        onLoadNextPage: noop,
        onLocationChange: noop,
        isFetchingItems: false,
        isFetchingLocation: false,
        isFetchingPopularLocations: false,
        getListingSliderData: noop,
        setFromBundleResultsetType: noop,
        listingSliderType: null,
        generalConfig: {},
        filters: {},
        sessionFeatures: []
    };

    static fetchData(dispatch, renderProps, reqProps, { config }) {
        const promises = [];
        const { params, location, categoryTreeSelected, selectedLocation, location: { query: { expired = false } = {}} = {}} = renderProps;
        const isAtCityLevelAndCategoryTreeSelectedFlag = isAtCityLevelAndCategoryTreeSelected(categoryTreeSelected, selectedLocation);

        // Popular Searches
        if (!expired) {
            const variantInfo = {
                disabled: false,
                variant: 'a'
            };

            const isAutosPlatform = false;

            promises.push(dispatch(fetchPopularSearches(config, params, location)));

            if (isAtCityLevelAndCategoryTreeSelectedFlag) {
                promises.push(dispatch(getPopularLocationsByLimit(LOCATION_TYPE, LOCATION_LIMIT)));
            }
            promises.push(
                dispatch(fetchFiltersByCategoryIdActionCreator(isAutosPlatform, params.categoryID, variantInfo))
                    .then(filterData => {
                        const filterConfig = filterData && filterData.data && filterData.data.data;
                        const visualization = Array.isArray(filterConfig) && filterConfig.length > 0 && filterConfig[0][VISUALIZATION_KEY];
                        const defaultLayoutCode = visualization && visualization[visualizationFilters.VIEW_TYPE].defaultLayout;
                        const pwaLayoutCode = (defaultLayoutCode && viewTypeTanakConfig[defaultLayoutCode])
                            ? viewTypeTanakConfig[defaultLayoutCode].pwaCode : '';

                        return dispatch(visualizationTypeActionCreator(pwaLayoutCode));
                    })
            );
        }

        return Promise.all(promises);
    }

    constructor(props) {
        super(props);

        this.geolocation = null;
        this.translations = {
            filters: <Translation id="filters" />,
            submitLocation: <Translation id="submitLocation" />,
            location: <Translation id="location" />,
            bucketSize: values => <Translation id="bucket-size" values={ values } />,
            nationWide: values => <Translation id="nation-wide" values={ values } />
        };
        this.carouselConfigFlag = props.marketConfig && props.marketConfig.get('reProjects', 'showCarousel');
        this.allCountry = props.marketConfig.get('allCountry');
        this.navigationBarEnabled = props.marketConfig.get('navigationBarEnabled');
        this.state = {
            adIdsToUpdate: [],
            loadFiltersV2: false,
            offline: false,
            pageSize: CARS_CATEGORY_PAGE_SIZE
        };

        this.showMXDesign = props.marketConfig.get('adpvAuto', 'showMXDesign');
        this.franchiseRedirection = props.marketConfig.get('franchiseRedirection');
        this.listingImpressions = {};
        this.headerRef = createRef();
        this.subHeaderRef = createRef();
        this.headerLogoRef = createRef();
        this.isFooterClicked = false;
    }

    componentDidMount() {
        addOfflineEventListeners(this);

        this.startTime = Date.now();
        const {
            params: { text },
            getListingSliderData,
            setFiltersTrack,
            setBrowsingMode,
            trackerOrigins,
            itemsParams,
            location,
            category,
            marketConfig
        } = this.props;
        const origin = text ? trackerOrigins.SEARCH : trackerOrigins.BROWSE;
        const filters = (itemsParams.query && itemsParams.query.filters)
            || decodeFilters(location.query && location.query.filter);

        const cookies = CookieManager.getAllCookies();

        if (filters) {
            setFiltersTrack(filters);
        }
        setBrowsingMode(origin);

        const listingSliderTypeConfig = getListingSliderType(marketConfig, category);

        if (listingSliderTypeConfig) {
            getListingSliderData({
                cookies,
                location: this.props.location,
                bundleType: listingSliderTypeConfig
            });
        }

        const locationProps = this.props.location;
        const bundleResultset = locationProps.state && locationProps.state.fromBundleResultset ? locationProps.state.fromBundleResultset : '';

        this.props.setFromBundleResultsetType(bundleResultset);
        this.track404IfNeeded();
        this.setVisualization();
        setSessionStorageItem(REQUEST_ORIGIN, REQUEST_ORIGINS.LISTING_PAGE);

        const isAndroid = typeof window === 'undefined' ? false : /android/i.test(window.navigator.userAgent);

        // temporary deeplink redirection only for android
        if (
            isAndroid
            && filters.olxautos_listing
            && this.franchiseRedirection
            && this.franchiseRedirection.android
        ) {
            window.location.href = this.franchiseRedirection.android;
        }

        if (window.navigator && window.navigator.onLine === false) {
            offlineHandler(this);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            marketConfig,
            category,
            setBrowsingMode,
            setOriginTrack
        } = this.props;

        if (prevProps.items.length !== this.props.items.length) {
            this.setAdIdsToUpdate();
        }

        if (this.props.params.text !== prevProps.params.text) {
            const origin = prevProps.params.text ? 'search' : 'browse';
            const browseMode = this.props.params.text ? 'search' : 'browse';

            setBrowsingMode(browseMode);
            setOriginTrack(origin);
        }

        if (
            this.props.isFetchingItems !== prevProps.isFetchingItems
            || (
                this.props.items !== prevProps.items
                && locationToString(this.props.location) !== locationToString(prevProps.location)
            )
        ) {
            this.track404IfNeeded(this.props);
        }

        const locationProps = this.props.location;
        const prevLocationProps = prevProps.location;

        // Inspected Car Slider
        if (
            locationProps.pathname !== prevLocationProps.pathname
                || locationProps.search !== prevLocationProps.search
        ) {
            const listingSliderTypeConfig = getListingSliderType(marketConfig, category);

            if (listingSliderTypeConfig) {
                const cookies = CookieManager.getAllCookies();
                const bundleResultset = locationProps.state && locationProps.state.fromBundleResultset ? locationProps.state.fromBundleResultset : '';

                this.props.setFromBundleResultsetType(bundleResultset);

                this.props.getListingSliderData({
                    cookies,
                    location: locationProps,
                    bundleType: listingSliderTypeConfig
                });
            }

            this.fireViewListingsImpressions(false, window?.location?.pathname, window?.location?.href);
            this.fireListingsResultSummary(prevProps);
        }
        const { filtersStateConfig: { open: isFiltersV2Open } = {}} = this.props;
        const { filtersStateConfig: { open: prevIsFiltersV2Open } = {}} = prevProps;

        if ((!!isFiltersV2Open !== !!prevIsFiltersV2Open) && !!isFiltersV2Open && !this.state.loadFiltersV2) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                loadFiltersV2: true
            });
        }

        this.isFooterClicked = false;
    }

    componentWillUnmount() {
        this.props.clearFilterRecencyOrder();
        const searchText = this.props.params.text;
        const resultsetType = searchText ? 'search' : 'browse';

        this.props.setOriginTrack(resultsetType);

        this.fireViewListingsImpressions(false, window?.location?.pathname, window?.location?.href);
        this.fireListingsResultSummary();

        removeOfflineEventListeners(this);
    }

    fireListingsResultSummary = (prevProps = {}) => {
        const {
            trafficOrigin,
            browseMode,
            location,
            items,
            itemsMetadata: {
                total,
                total_pages,
                total_suggested_pages,
                suggested_term,
                total_suggested_ads
            },
            params,
            marketConfig
        } = this.props;

        const {
            location: prevLocation,
            items: prevItems,
            itemsMetadata: {
                total: prev_total,
                total_pages: prev_total_pages,
                total_suggested_pages: prev_total_suggested_pages,
                suggested_term: prev_suggested_term,
                total_suggested_ads: prev_total_suggested_ads
            } = {},
            params: prevParams,
            browseMode: prevBrowseMode
        } = prevProps;

        const trackItems = prevItems || items;
        const trackParams = prevParams || params;
        const trackLocation = prevLocation || location;
        const trackBrowseMode = browseMode || prevBrowseMode;

        const searchText = trackParams.text;
        const resultsetType = searchText ? 'search' : 'browse';
        const source = trackLocation.state?.source;
        const { totalInspectedAds, totalFeaturedAds } = getItemCounts(trackItems);
        const listingImpressionsItemIds = Object.keys(this.listingImpressions);
        const visualizationType = marketConfig.get('visualizationType', PLATFORM);

        this.track(VIEW_LISTINGS_RESULTS_SUMMARY, {
            origin: trafficOrigin,
            browsing_mode: trackBrowseMode,
            resultset_type: source || resultsetType,
            page_number: trackLocation?.query.page || 1,
            inspected_ad_count: totalInspectedAds,
            search_string: searchText,
            search_string_suggested: prev_suggested_term || suggested_term,
            original_ads_count: prev_total || prev_total_suggested_ads || total || total_suggested_ads,
            total_page: prev_total_pages || prev_total_suggested_pages || total_pages || total_suggested_pages,
            fa_ad_count: totalFeaturedAds,
            inspected_ad_count_impression: listingImpressionsItemIds.filter(id => this.listingImpressions[id].isInspected).length,
            fa_ad_count_impression: listingImpressionsItemIds.filter(id => this.listingImpressions[id].isFeatured).length,
            result_count: prev_total || total,
            result_count_impression: listingImpressionsItemIds.length,
            visual_applied: visualizationType,
            time_spent: Math.floor((Date.now() - this.startTime) / TIME_FACTOR),
            quick_filter: this.props.appliedFilters.length,
            ...LayoutHelper.getAcquisitionChannelTrackingValues()
        });

        this.listingImpressions = {};
    }

    fireViewListingsImpressions() {
        /* istanbul ignore if */
        return;
    }

    unloadEventHandler = () => {
        this.fireViewListingsImpressions(true);
    }

    /**
     * Sets the visualisation in redux according to the default visualisation provided in config
     */
    setVisualization() {
        const { marketConfig, toggleVisualization } = this.props;
        const visualizationType = marketConfig.get('visualizationType', PLATFORM);

        if (
            typeof visualizationType === 'string'
        ) {
            toggleVisualization(visualizationType);
        }
    }

    track404IfNeeded({ isFetchingItems, items = [], location = {}} = this.props) {
        if (!isFetchingItems && !items.length) {
            this.track('page_error_404', {
                target_url: locationToString(location)
            });
        }
    }

    setAdIdsToUpdate = (ids = []) => {
        this.setState({ adIdsToUpdate: ids });
    };

    onClickPost = e => {
        e.preventDefault();

        const origin = this.props.trackerOrigins.LISTING;

        this.props.setOriginTrack(origin);
        this.track('posting_tap_post', {
            origin,
            select_from: 'sell_button'
        });

        if (Auth.isLoggedIn()) {
            this.props.router.push({
                pathname: '/post'
            });
        }
        else {
            this.props.setLoginModalState({ state: true, redirectTo: '/post', trackProps: { origin: 'posting' }});
        }
    };

    loadNextPage = () => {
        const { items } = this.props;

        this.setAdIdsToUpdate(['baxter-ads-results-bottom']);

        if (items && (items.cursor || items.nextPageUrl || items.nextSuggestedPageUrl)) {
            this.props.onLoadNextPage();
        }
        else {
            const path = this.props.location.pathname ? this.props.location.pathname : '';
            const searchParams = this.props.location.search ? path + this.props.location.search : '';

            this.props.router.push(
                helmetHelper.getSeoPage('next', searchParams)
            );
        }
    };

    onViewItem = impression => {
        this.props.impressions.add(impression);
        this.listingImpressions[impression.id] = impression;
    };

    trackListingApplySorting = filters => {
        const { params } = this.props;

        this.props.setFiltersTrack(filters).then(() => {
            const props = {
                filters: this.props.trackFilters,
                sorting_applied: filters.order,
                select_from: LISTING,
                resultset_type: this.props.trackOrigin,
                category_id: params.categoryID
            };

            this.track(LISTING_TAP_APPLY_SORT, props);
        });
    };

    trackListingSelectSorting = (trackingInfo = {}) => this.track(LISTING_TAP_SELECT_SORT, trackingInfo);

    trackListingSelectFilters = () => {
        const {
            itemsParams,
            itemsMetadata,
            trackFilters,
            params,
            marketConfig
        } = this.props;
        const visualizationType = marketConfig.get('visualizationType', PLATFORM);

        this.track(LISTING_TAP_SELECT_FILTERS, {
            filters: trackFilters,
            sorting_applied: itemsParams.sorting || (itemsMetadata.applied_sorting && itemsMetadata.applied_sorting.key),
            filter_count: Object.keys(trackFilters).length,
            visual_applied: visualizationType,
            select_from: SELECT_FROM.FILTER_PAGE,
            chosen_option: FILTER,
            category_id: params.categoryID
        });
    }

    track = (event, props) => {
        let updateItemProps = {
            ...props,
            city_id: getCityLocationId(this.props.selectedLocation)
        };

        if (!isEmpty(this.props.location.state)) {
            const extras = this.props.location.state;

            updateItemProps = { ...updateItemProps, ...extras };
        }
        this.props.track(event, updateItemProps);
    }
    renderBanners = (slot, adIdsToUpdate = []) => {
        const country = this.props.locationCountry;

        return (
            <AdvertisingComponent
                slot={ slot }
                page="results"
                country={ country }
                category={ this.props.categoryTreeSelected }
                adIdsToUpdate={ adIdsToUpdate }
                sticky={ slot === 'top-sticky' }
                className={ slot === 'top-sticky' ? css.stickyAd : '' }
            />
        );
    }

    getHelmet = items => {
        const defaultPageSize = 20;
        const location = this.props.location || {};
        const path = this.props.location.pathname ? this.props.location.pathname : '';
        const searchParams = this.props.location.search ? path + this.props.location.search : '';
        const searchPath = searchParams.replace(/[?&]page=[0-9]+/, '');
        const totalPages = items ? Math.ceil(items.total / defaultPageSize) : null;
        const currentPage = this.props.location && this.props.location.query && this.props.location.query.page || 1;
        const hostUrl = this.props.marketConfig.get('host');
        const links = helmetHelper.getLinks(currentPage, hostUrl, path, totalPages, searchParams, searchPath, items.nextPageUrl);
        const currentPath = location.pathname + location.search;
        const ampConfigEnabled = this.props.marketConfig.get('enableListingAMP');
        const showAMPLink = (ampConfigEnabled && !this.props.params.geoID) || (ampConfigEnabled && this.props.marketConfig.get('enableGeoListingAMP'));
        const ampURL = `${this.props.marketConfig.get('host')}/amp${currentPath}`;
        const ampLinkTagObj = [{ rel: 'amphtml', href: ampURL }];
        const crossLinkTags = helmetHelper.getCrossLinkedTag(this.props.marketConfig.get(), this.props.location);

        return (
            <React.Fragment>
                {!!showAMPLink && <Helmet link={ ampLinkTagObj } />}
                <Helmet link={ links.linkPrevPage } />
                <Helmet link={ links.linkNextPage } />
                { crossLinkTags.map(link => {
                    return <Helmet link={ link } />;
                })}
            </React.Fragment>
        );
    }

    getNav = page => {
        const { marketConfig, category, params, filtersStateConfig, craouselCategoryData, showBannerAndPopularSearch } = this.props;
        const showBannerInListing = showBannerAndPopularSearch && marketConfig.get('listing', 'showBannerInListing');
        const categoryID = category && category.id ? category.id : DEFAULT_CATEGORY;
        let filterPropsMobile = {
            trackListingSelectFilters: this.trackListingSelectFilters,
            open: this.props.isFiltersOpen,
            categoryID,
            key: `${`${params.text}-` || ''}${categoryID || DEFAULT_CATEGORY}`
        };

        filterPropsMobile = {
            ...filterPropsMobile,
            ...filtersStateConfig,
            renderNewFilters: true,
            loadFiltersV2: this.state.loadFiltersV2
        };

        return (
            <Nav
                banner={ this.renderBanners('top') }
                filterPropsMobile={ filterPropsMobile }
                page={ page }
                showBannerInListing={ showBannerInListing }
                links={ this.props.breadcrumbLinks }
                showViewType={ false }
                showSorting={ false }
                className={ classNames({ [css.oneLineButtons]: IS_DESKTOP }) }
                showListingCarousel={ craouselCategoryData?.enabled }
                widgetToFetch={ craouselCategoryData?.widgetName?.pwa_desktop }
                isClassified={ true }
            />
        );
    }

    getDynamicContent = () => {
        const { category, filters, selectedLocation } = this.props;

        return <DynamicContent { ...{ category, filter: filters, selectedLocation } } content={ this.props.dynamicContent } />;
    }

    gridClass = classNames(
        grid.col,
        grid.xl12, grid.l12,
        grid.m12,
        grid.s12,
        css.bannersContainer
    );

    notification = (
        this.props.location.query.expired && (
            <Notification type="warning" showCloseBtn={ true }>
                <Translation id="ad_expiration_not_available_title" />
            </Notification>
        ) || null
    );

    onItemClick = item => {
        const { category } = this.props;
        const user = Auth.getUser();

        if (!isEmpty(this.props.location.state) && user) {
            const trackParams = {
                item_id: item.id,
                category: category.id,
                user_id: user.id
            };

            this.track('maf_view_item', trackParams);
        }
    }

    getItems = ({ location }, currentPage) => {
        const params = {
            ...location.query,
            page: currentPage
        };
        const url = buildURL({ params, base: location.pathname, persistPage: true });

        this.props.router.replace(url);
        scrollToTop();
    };

    getNextPage = paginationData => {
        this.getItems(this.props, paginationData.currentPage);
    };

    renderPagination = (isFetchingItems, listingBodyTypes) => () => {
        const { itemsMetadata, location } = this.props;
        const total = listingBodyTypes === LISTING_BODY_TYPE.RELAXED ? itemsMetadata.total_similar_ads : this.props?.items?.total || this.state.pageSize;
        const MAX_ITEMS = 500;

        return !isFetchingItems && <PaginationWrapper onPageChanged={ this.getNextPage } currentPage={ location.query?.page || 1 } total={ total } location={ location } pageSize={ this.state.pageSize } maxItems={ MAX_ITEMS } />;
    }
    listingBody = (page, items, isFetchingItems, similarAdsCount, listingBodyType) => {
        const {
            category,
            categoryTreeSelected,
            isFetchingLocation,
            isSortingOpen,
            itemsMetadata,
            location,
            marketConfig,
            params,
            parentItems,
            sectionsItems,
            suggestedItemsSections,
            listingSliderType,
            similarAdsData,
            currentPageItems,
            itemsPageIndex,
            sessionFeatures
        } = this.props;

        // NOTE: Hide the main listing page from page 2 onwards when relaxed filters are applied.
        if (location.query.page > 1
            && items.length === 0
            && listingBodyType === LISTING_BODY_TYPE.MAIN
            && similarAdsData?.length > 0
        ) {
            return null;
        }

        const categoryID = category && category.id ? category.id : DEFAULT_CATEGORY;
        const extraTrackAttr = { feed_version: itemsMetadata.feed_version };
        const visualizationType = marketConfig.get('visualizationType', PLATFORM);
        const isStaticFilters = !!similarAdsData?.length && !similarAdsCount && !items?.length;
        const staticAsseturl = marketConfig.get('staticAssets');

        const noResultZrpImageURL = `${staticAsseturl}/external/base/img/lessItemCarsZrpBanner.svg`;

        let listingHeader = <div className={ css.listingHeaderContainer } />;
        const sectionOffset = sectionsItems?.map(({ offset }) => offset) || [];

        if (isFetchingItems || items.length || !!similarAdsData?.length) {
            listingHeader = (<ListingHeader
                categoryID={ categoryID }
                similarAdsCount={ similarAdsCount }
                similarAdsData={ similarAdsData }
                isStaticFilters={ isStaticFilters }
                isFetchingLocation={ isFetchingLocation }
                isSortingOpen={ isSortingOpen }
                locationDetails={ listingHelper.parsePathname(location.pathname) }
                originalTotal={ items.total }
                suggestedItemsTotal={ items.totalSuggestedAds }
                trackListingSelectSorting={ this.trackListingSelectSorting }
                trackListingApplySorting={ this.trackListingApplySorting }
                visualizationType={ visualizationType }
                itemsMetadata={ itemsMetadata }
                dynamicContent={ this.getDynamicContent() }
                currentPage={ location.query.page || 1 }
                sectionOffset={ sectionOffset }
                sectionsItems={ sectionsItems }
            />);
        }

        const itemsListData = listingBodyType === LISTING_BODY_TYPE.RELAXED ? similarAdsData : currentPageItems;
        let itemsList = [];
        const flag = sessionFeatures.includes('olxin-3885');

        if (flag) {
            itemsList = similarAdsData?.length > 0 ? items : itemsListData;
        }
        else {
            itemsList = itemsListData;
        }

        // (tanmay-mazumdar) Consider a case where total similar ads = 110, on initial load 80 items are shown,
        // clicking on Load More cta it should show remaining 30 items only and no Load More cta should appear.
        const totalSimilarAds = itemsMetadata.total_similar_ads;
        const similarAdsPage = ((parseInt(itemsPageIndex, DECIMAL_RADIX) + 1) * SIMILAR_ADS_PAGE_SIZE);
        const hasReachedSimilarAdsLimit = totalSimilarAds >= similarAdsPage;
        const hasNextPage = !!items.nextSuggestedPageUrl || !!items.nextPageUrl;

        const showLoadMore = flag && listingBodyType === LISTING_BODY_TYPE.RELAXED
            ? hasReachedSimilarAdsLimit
            : hasNextPage;

        return (
            <ListingBody
                renderPagination={ this.renderPagination(isFetchingItems, listingBodyType) }
                adIdsToUpdate={ this.state.adIdsToUpdate }
                categoryID={ categoryID }
                enabledRealImpressions={ marketConfig.get('realImpressions').enabled }
                fourColumns={ !this.navigationBarEnabled }
                isFetchingItems={ isFetchingItems }
                items={ itemsList }
                categoryTree={ categoryTreeSelected }
                similarAdsData={ similarAdsData }
                similarAdsCount={ similarAdsCount }
                noResultZrpImageURL={ noResultZrpImageURL }
                listingHeader={ listingHeader }
                onItemClick={ this.onItemClick }
                onLoadNextPage={ this.loadNextPage }
                onViewItem={ this.onViewItem }
                originalTotal={ items.total }
                page={ page }
                parentItems={ parentItems }
                renderBanners={ this.renderBanners }
                sectionsItems={ sectionsItems }
                listingSliderType={ listingSliderType }
                showInspectionTag={ true }
                showLoadMore={ showLoadMore }
                showMXDesign={ false }
                source={ params.text ? ITEM_SOURCE.SEARCH : ITEM_SOURCE.BROWSE }
                suggestedItemsSections={ suggestedItemsSections }
                suggestedItemsTotal={ items.totalSuggestedAds }
                visualizationType={ visualizationType }
                extraTrackAttr={ extraTrackAttr }
                itemsMetadata={ itemsMetadata }
                listingBodyType={ listingBodyType }
                sessionFeatures={ sessionFeatures }
            />
        );
    }

    renderPageContent = () => {
        const { items, isFetchingItems, category, location, similarAdsData, itemsMetadata, marketConfig, generalConfig } = this.props;
        const page = location && location.query && Number(location.query.page) || 1;
        const isItemsNotVisible = !isFetchingItems && !items.length;

        const new_real_estate_feature = generalConfig?.features?.find(feature => feature.name === 'new_real_estate_enabled') || {};
        const new_real_estate_enabled = !!(new_real_estate_feature.enabled && new_real_estate_feature.data?.platform?.includes('pwa'));

        const showCarouselFlag = !!(this.carouselConfigFlag && !isItemsNotVisible && category && category.category_banner && new_real_estate_enabled);
        const itemsResult = isFetchingItems ? [] : items;
        const similarAds = isFetchingItems ? [] : similarAdsData;
        const similarAdsCount = similarAds?.length;
        const { seoCanonical } = this.props;
        const ListingPageContent = ListingPageContentOld;

        return (
            <React.Fragment>
                {this.getHelmet(itemsResult)}
                <Head items={ isFetchingItems || !itemsResult ? -1 : itemsResult.length } category={ category } seoCanonical={ seoCanonical } />
                { this.renderBanners('andbeyond') }
                <ListingPageContent
                    nav={ this.getNav(page) }
                    gridClasses={ this.gridClass }
                    notification={ this.notification }
                    // banners={ IS_DESKTOP && this.renderBanners('right') }
                    topBanners={ (itemsResult.length !== 0 || isFetchingItems) && this.renderBanners('top-sticky') }
                    listingBody={ this.listingBody(page, itemsResult, isFetchingItems, null, LISTING_BODY_TYPE.MAIN) }
                    similarAdsBody={ itemsMetadata.total_similar_ads ? this.listingBody(page, similarAds, isFetchingItems, similarAdsCount, LISTING_BODY_TYPE.RELAXED) : null }
                    similarAdsData={ similarAdsData }
                    originalTotal={ itemsResult.length }
                    isFetchingItems={ isFetchingItems }
                    navigationBarEnabled={ this.navigationBarEnabled }
                    showCarouselFlag={ showCarouselFlag }
                    location={ this.props.location }
                    onClickPost={ this.onClickPost }
                    topSearchesEnabled={ this.topSearchesEnabled }
                    category={ category }
                    config={ marketConfig }
                    isSimilarAds={ !!IS_MOBILE }
                    offline={ this.state.offline }
                    params={ this.props.params }
                    dynamicContent={ this.getDynamicContent() }
                />
            </React.Fragment>
        );
    }

    renderOLXAutosHeader() {
        const { marketConfig: config, category } = this.props;
        const categoryID = category && category.id ? category.id : DEFAULT_CATEGORY;
        const olxAutosConfig = config.get('olxAutos') || {};
        const { showOlxAutosHeader = false } = olxAutosConfig;

        const showHeader = showOlxAutosHeader && (categoryID == olxAutosConfig.carCategory);

        return showHeader ? <OLXAutosHeaderWrapper showSearchbar /> : null;
    }

    render() {
        const { location, category, marketConfig } = this.props;
        const topFooterSections = [];
        const onFooterClick = () => {
            this.isFooterClicked = true;
        };

        const footer = <Footer sections={ this.props.footer } enableTopMobile topSections={ topFooterSections } onFooterClick={ onFooterClick } showMgid={ marketConfig.get('mgid').enabled } />;
        const olxAutosHeader = this.renderOLXAutosHeader();
        const header = (<SearchHeader
            headerRef={ this.headerRef }
            showTitle
            enableFiltersMenu={ true }
            enableSearchInput={ !olxAutosHeader }
            headerClass={ css.searchHeader }
            categoryId={ category.id }
            enableLocationMenu
            flowStep={ CAR_COMPARE_FLOW_STEP.LISTING }
        />);
        // eslint-disable-next-line no-nested-ternary
        const subHeader = <SubHeader showBackToTop />;
        const { query: { expired = false } = {}} = location || {};
        const pageBackgroundColorProp = WHITE_TEXT;

        return (
            <Page
                scrollId="listing"
                header={ header }
                footer={ footer }
                subHeader={ subHeader }
                olxAutosHeader={ olxAutosHeader }
                pageBgColor={ pageBackgroundColorProp }
            >
                <div className={ classNames({
                    [css.olxAutosHeaderShown]: !!olxAutosHeader
                }) } >
                    {!expired && this.renderPageContent()}
                </div>
            </Page>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    const categoryID = categoryIdSelector(state, ownProps.params);
    const showBannerCarouselforCategory = getShowBannerCarousel(configSelector(state)?.features, BANNER_CAROUSEL_WIDGETS.SHOW_BANNER_CAROUSEL_FOR_CATEGORY);
    const craouselCategoryData = getCarouselCategoryData(showBannerCarouselforCategory, categoryID);
    const showBannerAndPopularSearch = configSelector(state)?.buyers?.listing?.showBannerInListing;
    const category = state.categories.elements[categoryID];
    const filters = decodeRelevanceFilters(ownProps?.location?.query?.filter);
    const dynamicContentType = getDynamicContentType(state, ownProps?.location?.query, ownProps.params);

    return {
        categoryTreeSelected: getCategoryTree(state.categories.elements, categoryID),
        isFiltersOpen: state.toggleFilters,
        filtersStateConfig: state.toggleFiltersTanak,
        isSortingOpen: state.toggleSorting,
        trackFilters: state.track.filters,
        trackFiltersMetadata: state.track.filtersMetadata,
        trackOrigin: state.track.origin,
        breadcrumbLinks: listingBreadcrumbSelector(state, ownProps),
        category,
        sectionsItems: itemsSectionsSelector(state, ownProps),
        suggestedItemsSections: suggestedSectionsSelector(state, ownProps),
        listingSliderType: listingSliderTypeSelector(state, { marketConfig: ownProps.marketConfig, category: ownProps.categoryById }) || null,
        trafficOrigin: LayoutSelector.getTrackingOrigin(state, ownProps.location, ownProps.marketConfig),
        browseMode: state.track.browsingMode,
        appliedFilters: getTags(state, categoryID),
        selectedLocation: selectedLocationSelector(state),
        itemsPageIndex: itemsPageIndexSelector(state),
        craouselCategoryData,
        showBannerAndPopularSearch,
        generalConfig: configSelector(state),
        filters,
        dynamicContent: state.dynamicContent?.collections?.[dynamicContentType] || {}
    };
};

export const mapDispatchToProps = dispatch => ({
    toggleSorting: state => dispatch(toggleSorting(state)),
    toggleVisualization: state => dispatch(visualizationTypeActionCreator(state)),
    changeLocationTrack: () => dispatch(changeLocation()),
    setFiltersTrack: filters => dispatch(setFilters(filters)),
    setOriginTrack: origin => dispatch(setOriginSync(origin)),
    setBrowsingMode: mode => dispatch(setBrowsingModeSync(mode)),
    setFromBundleResultsetType: type => dispatch(setFromBundleResultsetType(type)),
    setLoginModalState: state => dispatch(setLoginModalState(state)),
    clearFilterRecencyOrder: () => dispatch(clearFilterRecencyOrder()),
    getListingSliderData: ({ cookies, location, bundleType }) => dispatch(getListingSliderDataActionCreator({ cookies, location, bundleType }))
});

const connectedComponent = compose(
    withConfig('marketConfig'),
    withDynamicContent,
    withItems(ITEMS_SEARCH_SOURCE, FACET_LIMIT, null, {
        shouldFetchFiltersFirst: true,
        refetchAds: true
    }),
    withLocation,
    withTrack,
    withSessionFeature,
    withSEOTags('listing', listingHelper.routerParamsToSeoQuery),
    connect(mapStateToProps, mapDispatchToProps)
    // withSkinAds('results')
)(ListingPage);

export default connectedComponent;
